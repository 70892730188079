import { Grid } from '@platform-ui/design-system';
import React, { FC } from 'react';
import Interval from './components/Interval';
import ScheduleRepeats from './components/ScheduleRepeats';
import Timezone from './components/Timezone';
import { timezones as TIMEZONES } from './constants';

interface CronBuilderProps {
  cron: { timezone: string; expression: string[] };
  setCron: React.Dispatch<React.SetStateAction<{ timezone: string; expression: string[] }>>;
  timezones?: Array<{ value: string, label: string, name: string }>;
  gridSpacing?: number;
  noLeadingSeconds?: boolean;
  showCronMessage?: boolean;
  workflowCronFormat?: boolean;
}

const CronBuilder: FC<CronBuilderProps> = (props) => {
  const {
    cron,
    setCron,
    timezones = TIMEZONES,
    gridSpacing = 12,
    noLeadingSeconds = false,
    showCronMessage = false,
    workflowCronFormat = true
  } = props;

  return (
    <Grid spacing={0} container>
      <Grid item xs={gridSpacing}>
        <Timezone
          timezone={cron.timezone}
          timezones={timezones}
          onChange={(value: { value: string }) => setCron({ ...cron, timezone: value.value })}
        />
      </Grid>
      <Grid item xs={gridSpacing} />
      <Grid item xs={gridSpacing} className='schedule-repeats-container'>
        <ScheduleRepeats
          workflowCronFormat={workflowCronFormat}
          onChange={(cronExpression: string[]) => setCron({ ...cron, expression: cronExpression })}
        />
      </Grid>
      <Grid item xs={gridSpacing || 12} />
      <Grid item xs={gridSpacing || 12} className='schedule-interval-field'>
        <Interval
          interval={noLeadingSeconds ? cron.expression.slice(1).join(' ').trim() : cron.expression.join(' ').trim()}
          showCronMessage={showCronMessage}
        />
      </Grid>
    </Grid>
  );
};

export default CronBuilder;